import { DropDown, LanguageFlag as Flag, LanguageList } from '@marsenit/gameout-ui';
import { LangContext, LangDispatchContext } from 'context/lang';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

const LanguageFlag: FC<Props> = ({ className = '' }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [flagElement, setFlagElement] = useState<HTMLElement>();
  const { i18n } = useTranslation();
  const { lang: language } = useContext(LangContext);
  const langDispatch = useContext(LangDispatchContext);

  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    setFlagElement(e.currentTarget);

    setModalVisible(true);
  };

  const onCloseHandler = () => setModalVisible(false);

  const onLanguageSelect = (code?: string) => {
    onCloseHandler();

    if (code) {
      i18n.changeLanguage(code);
      langDispatch({ lang: code });
    }
  };

  return (
    <>
      <Flag name={language || 'en'} className={className} onClick={onClickHandler} />
      {isModalVisible && (
        <DropDown target={flagElement!} onClose={onCloseHandler}>
          <LanguageList onSelect={onLanguageSelect} />
        </DropDown>
      )}
    </>
  );
};

export default LanguageFlag;
