import { API_URL } from 'config';
import { use } from 'react';

const promiseCache = new Map<string, Promise<unknown>>();

export function useQuery<T>(uri: string) {
  const request = async () => {
    const res = await fetch(`${API_URL}/user/v1/terms`);
    return res?.json();
  };

  if (!promiseCache.has(uri)) {
    promiseCache.set(uri, request());
  }

  const promise = promiseCache.get(uri) as Promise<T>;
  return use(promise);
}
