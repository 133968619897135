import Terms from 'components/Terms/Terms';
import 'assets/css/terms.scss';
import { API_URL } from 'config';
import StringObject from 'interfaces/stringObject';
import { useQuery } from 'hooks/useQuery';

const PrivacyPolicy = () => {
  const res = useQuery<{ data: StringObject }>(`${API_URL}/user/v1/privacy`);

  return (
    <div className="mt-5">
      <Terms data={res.data} />
    </div>
  );
};

export default PrivacyPolicy;
