import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from 'pages/Landing/Landing';
import TermsOfService from 'pages/TermsOfService/TermsOfService';
import Privacy from 'pages/Privacy/Privacy';
import Guidelines from 'pages/Guidelines/Guidelines';
import AccountDeletion from 'pages/AccountDeletion/AccountDeletion';
import NotFound from 'pages/NotFound/NotFound';
import Developer from 'pages/Developer/Developer';
import LangProvider from 'providers/langProvider';

const App = () => {
  return (
    <Suspense fallback={null}>
      <LangProvider>
        <Router basename={'/'}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="terms" element={<TermsOfService />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="guidelines" element={<Guidelines />} />
            <Route path="delete-account" element={<AccountDeletion />} />
            <Route path="developer" element={<Developer />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </LangProvider>
    </Suspense>
  );
};

export default App;
