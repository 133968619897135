import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classes from './Button.module.scss';
import classNames from 'classnames';

// Images
import spinnerSvg from 'assets/images/spinner.svg';

export interface ButtonProps {
  to?: string;
  type?: string;
  className?: string;
  loading?: boolean;
  globalClasses?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  to,
  type,
  className,
  loading = false,
  globalClasses = '',
  onClick,
  children,
}) => {
  const Element: any = to ? Link : 'button';
  const propClasses = className
    ?.split(' ')
    .map((cn) => classes[`button--${cn}`])
    .join(' ');

  const buttonClasses = classNames('btn', classes.button, propClasses, globalClasses, {
    [classes.loading]: loading,
  });

  return (
    <Element
      {...(to ? { to: to } : {})}
      {...(type ? { type } : {})}
      className={buttonClasses}
      onClick={onClick}>
      {children}
      {loading && (
        <>
          &nbsp;&nbsp;
          <img src={spinnerSvg} alt="" className={classes.spinner} />
        </>
      )}
    </Element>
  );
};

export default Button;
