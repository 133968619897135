import React from 'react';
import NavBar from 'components/NavBar/NavBar';
import PhoneAnimated from 'components/PhoneAnimated/PhoneAnimated';
import About from 'components/About/About';
import Features from 'components/Features/Features';
import Work from 'components/Work/Work';
import GetNow from 'components/GetNow/GetNow';
import AnimatedCircle from 'components/AnimatedCircle/AnimatedCircle';
import Footer from 'components/Footer/Footer';
import { FaAngleUp } from 'react-icons/fa';
import classes from './Landing.module.scss';

import app1Png from 'assets/images/app1.png';
import app2Png from 'assets/images/app2.png';

const Landing = () => {
  return (
    <div className={classes.bg}>
      <NavBar />

      <section id="home" className={`section slide-bg ${classes['slide-bg']}`}>
        <AnimatedCircle />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex-1">
                <div className={`slide-text z-index-1000 ${classes['slide-text']}`}>
                  <div>
                    <h1>Stay connected to your favorite games wherever you are</h1>
                    <h4>
                      With Gameout you can send and receive messages and notifications from your
                      favorite games in real time.
                    </h4>
                    <div className={`slid-btn ${classes['slid-btn']}`}>
                      <a href="https://play.google.com/store/apps/details?id=gameout.app">
                        <img src={app1Png} alt="Play Store" className={classes.store} />
                      </a>
                      <a href="https://apps.apple.com/us/app/gameout-your-game-connection/id1567877472">
                        <img src={app2Png} alt="App Store" className={classes.store} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PhoneAnimated />
          </div>
        </div>
      </section>

      <About />
      <Features />
      <Work />
      <GetNow />
      <Footer />

      <div className="tap-top">
        <div>
          {/* 
// @ts-ignore */}
          <FaAngleUp aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
