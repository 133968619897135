import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from 'pages/Landing/Landing';
import Legal from 'pages/Legal/Legal';
import AccountDeletion from 'pages/AccountDeletion/AccountDeletion';
import NotFound from 'pages/NotFound/NotFound';
import Developer from 'pages/Developer/Developer';
import LangProvider from 'providers/langProvider';
import { LegalData } from 'enums/legalData';

const App = () => {
  return (
    <LangProvider>
      <Router basename={'/'}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="terms" element={<Legal type={LegalData.TermsOfService} />} />
          <Route path="privacy" element={<Legal type={LegalData.PrivacyPolicy} />} />
          <Route path="guidelines" element={<Legal type={LegalData.CommunityGuidelines} />} />
          <Route path="delete-account" element={<AccountDeletion />} />
          <Route path="developer" element={<Developer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </LangProvider>
  );
};

export default App;
