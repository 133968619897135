import { FC, use } from 'react';
import { LanguageSelectorModal as LangModal } from '@marsenit/gameout-ui';
import { useTranslation } from 'react-i18next';
import { LangDispatchContext } from 'context/lang';

interface Props {
  onClose: () => void;
}

const LanguageSelectorModal: FC<Props> = ({ onClose = () => null }) => {
  const langDispatch = use(LangDispatchContext);
  const { i18n } = useTranslation();

  const onLanguageSelect = (code?: string) => {
    onClose();

    if (code) {
      i18n.changeLanguage(code);
      langDispatch({ lang: code });
    }
  };

  return <LangModal onSelect={onLanguageSelect} />;
};

export default LanguageSelectorModal;
