import { createRoot } from 'react-dom/client';
import 'i18n/config';
import './assets/js/script.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
