import { LangContext, LangDispatchContext } from 'context/lang';
import { LangState } from 'interfaces/lang';
import { useState } from 'react';
import { getLocale } from 'utils/user-locale';

const LangProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const initLang = localStorage.getItem('lang') || getLocale();
  const [lang, setLang] = useState<LangState>({ lang: initLang });

  const handleLangChange = (state: LangState) => {
    setLang(state);
    localStorage.setItem('lang', state.lang);
  };

  return (
    <LangContext.Provider value={lang}>
      <LangDispatchContext.Provider value={handleLangChange}>
        {children}
      </LangDispatchContext.Provider>
    </LangContext.Provider>
  );
};

export default LangProvider;
