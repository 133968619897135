import { createRoot } from 'react-dom/client';
import 'i18n/config';
import './assets/js/script.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './App';
import { StrictMode } from 'react';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
