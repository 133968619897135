import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FaAlignJustify } from 'react-icons/fa';
import classes from './NavBar.module.scss';
import NavbarProps from 'interfaces/navbarProps';
import * as bootstrap from 'bootstrap';

// images
import logoPng from 'assets/images/logo.png';
import Button from 'components/Button/Button';
import LanguageFlag from 'components/LanguageFlag/LanguageFlag';
import LanguageSelectorModal from 'components/LanguageSelectorModal/LanguageSelectorModal';

interface Props extends NavbarProps {
  t: any;
}

interface State {
  isLanguageModalVisible: boolean;
}

class NavBar extends Component<Props> {
  scrollSpy: any;

  state: State = {
    isLanguageModalVisible: false,
  };

  componentDidMount() {
    this.scrollSpy = new bootstrap.ScrollSpy(document.body, {
      target: '#navbar',
    });
  }

  componentWillUnmount() {
    if (this.scrollSpy) this.scrollSpy.dispose();
  }

  navigateToDevelopers() {
    window.location.replace('https://developer.gameout.app/login');
  }

  showLanguageModal = () => {
    this.setState((state) => ({
      isLanguageModalVisible: true,
    }));
  };

  hideLanguageModal = () => {
    this.setState((state) => ({
      isLanguageModalVisible: false,
    }));
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <nav
          id="navbar"
          className={`navbar navbar-expand-lg theme-nav fixed-top ${classes.navbar} ${classes['theme-nav']}`}>
          <div className="container">
            <div>
              <a
                className={`navbar-brand ${classes['navbar-brand']}`}
                href={`${process.env.PUBLIC_URL}/`}>
                <img src={logoPng} alt="logo" className={classes.logo} />
              </a>
            </div>
            {!this.props.pageId?.startsWith('#developer') && (
              <button
                className={`navbar-toggler ${classes['navbar-toggler']}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainmenu"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className={classes['navbar-toggler-icon']}>
                  {/* 
// @ts-ignore */}
                  <FaAlignJustify color="#FFFFFF" aria-hidden="true" />
                </span>
              </button>
            )}
            <div className="collapse navbar-collapse" id="mainmenu">
              <div style={{ width: '100%' }}>
                <div className={classes.flexbox}>
                  <ul className={`navbar-nav ml-auto ${classes['navbar-nav']}`} id="mymenu">
                    {!this.props.pageId?.startsWith('#developer') && (
                      <li className={`nav-item ${classes['nav-item']}`}>
                        {this.props.pageId ? (
                          <Link to="/" className={`nav-link ${classes['nav-link']}`}>
                            {t('PAGES.HOME')}
                          </Link>
                        ) : (
                          <a
                            href={this.props.pageId ? '/' : '#home'}
                            className={`nav-link ${classes['nav-link']}`}>
                            {t('PAGES.HOME')}
                          </a>
                        )}
                      </li>
                    )}

                    {this.props.pageId && (
                      <li
                        className={`nav-item ${classes['nav-item']} ${classes['hide-on-mobile']}`}>
                        <a
                          className={`nav-link active ${classes['nav-link']}`}
                          href={this.props.pageId}>
                          {this.props.pageName}
                        </a>
                      </li>
                    )}

                    {this.props.pageId === '#developer' && (
                      <li
                        className={`nav-item ${classes['nav-item']} ${classes['hide-on-desktop']}`}>
                        <Link to="login" className={`nav-link active ${classes['nav-link']}`}>
                          Sign In
                        </Link>
                      </li>
                    )}

                    {!this.props.pageId && (
                      <>
                        <li className={`nav-item ${classes['nav-item']}`}>
                          <a className={`nav-link ${classes['nav-link']}`} href="#about">
                            {t('PAGES.ABOUT')}
                          </a>
                        </li>
                        <li className={`nav-item ${classes['nav-item']}`}>
                          <a className={`nav-link ${classes['nav-link']}`} href="#features">
                            {t('PAGES.FEATURES')}
                          </a>
                        </li>
                        <li className={`nav-item ${classes['nav-item']}`}>
                          <Link to="/developer" className={`nav-link ${classes['nav-link']}`}>
                            {t('PAGES.DEVELOPERS')}
                          </Link>
                        </li>
                      </>
                    )}

                    <li
                      className={`nav-item ${classes['nav-item']} ${classes['hide-on-desktop']}`}
                      onClick={this.showLanguageModal}>
                      <span className={`nav-link active ${classes['nav-link']}`}>
                        Change Language
                      </span>
                    </li>
                  </ul>
                  <div className={classes['navbar-right']}>
                    <LanguageFlag
                      className={`${classes['navbar-right__lang-icon']} ${classes['hide-on-mobile']}`}
                    />
                    {this.props.pageId === '#developer' && (
                      <Button
                        className="secondary medium"
                        globalClasses={classes['navbar-right__sign-in']}
                        onClick={this.navigateToDevelopers}>
                        Sign In
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {this.state.isLanguageModalVisible && (
          <LanguageSelectorModal onClose={this.hideLanguageModal} />
        )}
      </>
    );
  }
}

export default withTranslation()(NavBar);
