import { FC } from 'react';
import { Spinner as GOSpinner } from '@marsenit/gameout-ui';
import styles from './Loading.module.scss';

const Loading: FC = () => {
  return (
    <div className={styles.container}>
      <GOSpinner />
    </div>
  );
};

export default Loading;
