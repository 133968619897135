import AnimatedCircle from 'components/AnimatedCircle/AnimatedCircle';
// import Loading from 'components/Loading/Loading';
import NavBar from 'components/NavBar/NavBar';
// import { Suspense } from 'react';

interface Props {
  pageId: string;
  pageName: string;
  children: React.ReactNode;
}

const PageWrapper: React.FC<Props> = ({ pageId, pageName, children }) => {
  return (
    <div>
      <NavBar pageId={'#' + pageId} pageName={pageName} />

      <section id={pageId} className="section bg">
        <AnimatedCircle />

        {/* <Suspense fallback={<Loading />}> */}
        <div id="wrapper">{children}</div>
        {/* </Suspense> */}
      </section>
    </div>
  );
};

export default PageWrapper;
