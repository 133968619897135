import { Suspense, useEffect } from 'react';
import { LegalData } from 'enums/legalData';
import Loading from 'components/Loading/Loading';
import TermsOfService from './TermsOfService/TermsOfService';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import CommunityGuidelines from './CommunityGuidelines/CommunityGuidelines';

interface Props {
  type: LegalData;
}

const Legal: React.FC<Props> = ({ type }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWrapper pageId="terms" pageName="Terms of Service">
      <Suspense fallback={<Loading />}>
        <div className="mt-5">
          {type === LegalData.TermsOfService && <TermsOfService />}
          {type === LegalData.PrivacyPolicy && <PrivacyPolicy />}
          {type === LegalData.CommunityGuidelines && <CommunityGuidelines />}
        </div>
      </Suspense>
    </PageWrapper>
  );
};

export default Legal;
